<template>
    <div style="background:#fff;">
        <a-card title="红包激励详情" style="width: 100%; min-height: 100%" :bordered="false">

            <label>激励类型：</label>
            <a-dropdown>
                <a-button class="ant-dropdown-link" @click="(e) => e.preventDefault()" v-model="OrRewardsType"
                          :disabled="disabled">
                    {{ PromotionName }}
                    <a-icon type="down" />
                </a-button>
                <a-menu slot="overlay">
                    <template v-for="item in PromotionList">
                        <a-menu-item :key="item.key">
                            <a @click="changePromotionType(item)">
                                {{item.value}}
                            </a>
                        </a-menu-item>
                    </template>
                </a-menu>
            </a-dropdown>



            <label style=" margin-left:10px">目标用户：</label>
            <a-dropdown>
                <a-button class="ant-dropdown-link" @click="(e) => e.preventDefault()" v-model="TargetUserType"
                          :disabled="disabled">
                    {{ TargetUseName }}
                    <a-icon type="down" />
                </a-button>
                <a-menu slot="overlay">
                    <template v-for="item in TargetUseList">
                        <a-menu-item :key="item.key">
                            <a>
                                {{item.value}}
                            </a>
                        </a-menu-item>
                    </template>
                </a-menu>
            </a-dropdown>





            <span style=" margin-left:10px">
                <label>数量：</label>
                <a-input-number style="margin:0 10px" v-model="RedEnvelopesNum" :disabled="disabled" />
            </span>
            <span>
                <label>单个红包：</label>
                <a-input-number style="margin:0 10px" v-model="OrRewardsMoney" :disabled="disabled" />
            </span>
            <span>
                <label>金额：</label>
                <a-input-number style="margin:0 10px" v-model="OrRechargeMoney" :disabled="disabled" />
            </span>
            <span>
                <label>有效期：</label>
                <a-input-number style="margin:0 10px" v-model="TermOfValidity" :disabled="disabled" />
            </span>

        </a-card>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                disabled: true,
                tableData: [],
                RedEnvelopesNum: 0,
                JLmoney: "",
                SumMoney: 0,
                OrRewardsType: "",
                TargetUserType: "",
                OrRewardsMoney: "",
                OrRechargeMoney: 0,
                PromotionList: [
                    { key: 0, value: "拉新" },
                    { key: 1, value: "购买" },
                    { key: 2, value: "领券" },
                    { key: 3, value: "阅读" }
                ],
                TargetUseList: [
                    { key: 0, value: "分享人" },
                    { key: 1, value: "阅读人" },
                    { key: 2, value: "团员" },
                ],
                PromotionName: "拉新",
                TargetUseName: "分享人",
                TermOfValidity:"",

            };
        },

        props: {//组件属性
        },
        methods: {//方法

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetOneInteractionData",
                    data: {
                        InteractionID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data;
                        console.log(self.tableData);
                        console.log(data.data.OrRechargeMoney);
                        self.RedEnvelopesNum = data.data.OrRechargeMoney / data.data.OrRewardsMoney;
                        console.log(self.RedEnvelopesNum);
                        self.OrRewardsType = data.data.OrRewardsType;
                        self.TargetUserType = data.data.TargetUserType;
                        self.OrRewardsMoney = data.data.OrRewardsMoney;
                        self.OrRechargeMoney = data.data.OrRechargeMoney;
                        var BeginDates = new Date(util.TimeStamptoDateTime(data.data.OrStartTime, 'yyyy-MM-dd hh:mm:ss'));
                        var EndDates = new Date(util.TimeStamptoDateTime(data.data.OrEndTime, 'yyyy-MM-dd hh:mm:ss'));
                        self.TermOfValidity = parseInt(Math.abs(Date.parse(EndDates) - Date.parse(BeginDates)) / 1000 / 60 / 60 / 24)+1;

                           self.PromotionList.forEach((e) => {
                                if (e.key == self.OrRewardsType) {
                                    self.PromotionName = e.value;
                                }
                           });
                          self.TargetUseList.forEach((e) => {
                                if (e.key == self.TargetUserType) {
                                    self.TargetUseName = e.value;
                                }
                           });
                      
                    }
                };
                http.Post(op);
            },

        
       
        },
        computed: {//计算属性
            
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
             var self = this;
            self.ID = self.$route.query.ID;
            this.getTableData();
        },
      
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };

   
</script>